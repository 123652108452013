import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BG from "./images/seq1.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./css/about.css";
import AOS from "aos";
import "aos/dist/aos.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import YouTube from "react-youtube";
import HeaderAbout from "./Header/HeaderAbout";
import Events from "../Events/Events";

import FooterAboutus from "./footer/FooterAboutus";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
// import { FaLinkedinIn } from 'react-icons/fa';

function AboutUs() {
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  useEffect(() => {
    AOS.init();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const opts = {
    height: "580px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const handleClickScroll = () => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });

    console.log("scroll");
  };

  const handleClickScrollAbout = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

    console.log("scroll");
  };

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <HeaderAbout func={handleClickScroll} func1={handleClickScrollAbout} />
      {/* <Box
        sx={{
          padding: "15%",
        }}
        data-aos="fade-down"
        data-aos-delay="120"
        data-aos-duration="3000"
      >
        <img
          src={require("./images/ani 1.png")}
          style={{
            width: "100%",
          }}
        />
      </Box> */}

      <Box
        id="about"
        className="desktop-team"
        sx={{
          marginTop: "10%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "38px",
            lineHeight: "46px",
            color: "#1b8fd2",
            textAlign: "center",
          }}
          gutterBottom
          data-aos="fade-up"
          data-aos-delay="120"
          data-aos-duration="3000"
        >
          Meet Our Team
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "2%",
          }}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Card
                sx={{
                  width: "230px",
                  height: "350px",
                  border: "1px solid #ccc",
                  marginTop: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  position: "relative",
                  boxShadow:
                    "0 4px 8px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-20px) ",
                    boxShadow:
                      "0 8px 16px rgba(0,0,0,0.2), 0 12px 40px rgba(0,0,0,0.19)",
                  },
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Start from the top of the card
                    left: 0,
                    width: "100%",
                    height: "25%", // Adjust height to cover the top part including the circle
                    backgroundColor: "#f0f0f0", // Grey background color
                    // Ensure the background is behind the card content
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />

                <Grid container direction="column" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    data-aos="fade-up"
                    data-aos-delay="280"
                    data-aos-duration="3000"
                    sx={{ position: "absolute", top: "-50px" }}
                  >
                    <div
                      className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                      style={{ textAlign: "center", color: "#D9D9D9" }}
                    >
                      <img
                        src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1718772742088_Vinod%20Abrol.png"
                        className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                        alt="Vinod Abrol"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "4px solid white",
                        }}
                      />
                      <a
                        href="https://www.linkedin.com/mwlite/in/vinod-abrol-622a7713"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: "absolute",
                          top: "99%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{
                              width: "24px",
                              height: "24px",
                              fill: "#0077b5",
                            }}
                          >
                            <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.46C23.21 24 24 23.22 24 22.25V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.57c-1.14 0-2.06-.93-2.06-2.06 0-1.14.92-2.07 2.06-2.07 1.14 0 2.07.93 2.07 2.07 0 1.13-.93 2.06-2.07 2.06zm14.65 12.88h-3.56v-5.87c0-1.4-.03-3.2-1.95-3.2-1.95 0-2.25 1.52-2.25 3.09v5.98H8.67V9h3.42v1.56h.05c.48-.91 1.64-1.86 3.37-1.86 3.6 0 4.27 2.37 4.27 5.45v6.3z" />
                          </svg>
                        </Box>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "0 10%",
                        marginTop: "60px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "25%",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                        Vinod Abrol
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          display: "inline",
                          marginleft: "10px",
                        }}
                      >
                        Legal Advisor
                      </Typography>

                      <Box
                        sx={{
                          marginTop: "65px",
                          height: "120px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": { display: "none" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sedan",
                            fontSize: "14px",
                            color: "#333",
                            fontSize: "0.9rem !important",
                            lineHeight: "1.4",
                          }}
                        >
                          A Chartered Accountant over 24 years of experience,
                          specializing in investment, fund raising, and digital
                          transformation. Notable roles include CFO at
                          Jabong.com, leading its integration Flipkart, and
                          significant contribution to startups fund raising.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  width: "230px",
                  height: "350px",
                  border: "1px solid #ccc",
                  marginTop: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  position: "relative",
                  boxShadow:
                    "0 4px 8px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow:
                      "0 8px 16px rgba(0,0,0,0.2), 0 12px 40px rgba(0,0,0,0.19)",
                  },
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Start from the top of the card
                    left: 0,
                    width: "100%",
                    height: "25%", // Adjust height to cover the top part including the circle
                    backgroundColor: "#f0f0f0", // Grey background color
                    // Ensure the background is behind the card content
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <Grid container direction="column" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    data-aos="fade-up"
                    data-aos-delay="280"
                    data-aos-duration="3000"
                    sx={{ position: "absolute", top: "-50px" }}
                  >
                    <div
                      className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        // src={require("./images/Sujit-Jha-1.png")}
                        src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1718772693649_Sujit%20Jha.png"
                        className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "4px solid white",
                        }}
                      />
                      <a
                        href="https://www.linkedin.com/mwlite/in/sujit-jha-3646b665"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: "absolute",
                          top: "99%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {/* <FaLinkedinIn style={{ fontSize: '30px', color: '#0077b5', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }} /> */}
                        {/* <LinkedInIcon style={{ fontSize: '30px', color: '#0077b5' }} /> */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{
                              width: "24px",
                              height: "24px",
                              fill: "#0077b5",
                            }}
                          >
                            <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.46C23.21 24 24 23.22 24 22.25V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.57c-1.14 0-2.06-.93-2.06-2.06 0-1.14.92-2.07 2.06-2.07 1.14 0 2.07.93 2.07 2.07 0 1.13-.93 2.06-2.07 2.06zm14.65 12.88h-3.56v-5.87c0-1.4-.03-3.2-1.95-3.2-1.95 0-2.25 1.52-2.25 3.09v5.98H8.67V9h3.42v1.56h.05c.48-.91 1.64-1.86 3.37-1.86 3.6 0 4.27 2.37 4.27 5.45v6.3z" />
                          </svg>
                        </Box>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "0 10%",
                        marginTop: "60px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "25%",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                        Sujit Jha
                      </Typography>
                      <Typography
                        sx={{
                          display: "inline",
                          marginleft: "10px",
                          fontSize: "16px",
                        }}
                      >
                        Head of Finance
                      </Typography>
                      {/* <Box sx={{marginTop: '65px', height: '120px', overflowY: 'auto' }}>
                        <Typography sx={{ fontFamily: 'Sedan', fontSize: '14px', color: '#333', fontsize: '0.8rem !important', lineheight: '1.4' }}>
                          As a Financial Leader, I oversee and manage group finances, ensuring profitability and long term sustainability while mantaining compliance with goverment and coperate policies. I develop and implement effective mental controls, manage oraganizational
                        </Typography> */}
                      <Box
                        sx={{
                          marginTop: "65px",
                          height: "120px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": { display: "none" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sedan",
                            fontSize: "14px",
                            color: "#333",
                            fontSize: "0.9rem !important",
                            lineHeight: "1.4",
                          }}
                        >
                          As a Financial Leader, I oversee and manage group
                          finances, ensuring profitability and long term
                          sustainability while maintaining compliance with
                          government and corporate policies. I develop and
                          implement effective mental controls, manage
                          organizational...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  width: "230px",
                  height: "350px",
                  border: "1px solid #ccc",
                  marginTop: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  position: "relative",
                  boxShadow:
                    "0 4px 8px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-20px) scale(1.05)",
                    // boxShadow: '0 8px 16px rgba(0,0,0,0.2), 0 12px 40px rgba(0,0,0,0.19)',
                    boxShadow:
                      "0 10px 20px rgba(0, 0, 0, 0.3), 0 14px 28px rgba(0, 0, 0, 0.25)",
                  },
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Start from the top of the card
                    left: 0,
                    width: "100%",
                    height: "25%", // Adjust height to cover the top part including the circle
                    backgroundColor: "#5CC0E1", // Grey background color
                    // Ensure the background is behind the card content
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <Grid container direction="column" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    data-aos="fade-up"
                    data-aos-delay="280"
                    data-aos-duration="3000"
                    sx={{ position: "absolute", top: "-50px" }}
                  >
                    <div
                      className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        // src={require("./images/Gangadhar_CEO (1).png")}
                        src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1718772655935_Gangadhar%20Sir.png"
                        className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "4px solid white",
                        }}
                      />
                      <a
                        href="https://www.linkedin.com/in/gangadhar-reddy-502806a8"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: "absolute",
                          top: "99%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {/* <LinkedInIcon style={{ fontSize: '30px', color: '#0077b5' }} /> */}
                        {/* <FaLinkedinIn style={{ fontSize: '30px', color: '#0077b5', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }} /> */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{
                              width: "24px",
                              height: "24px",
                              fill: "#0077b5",
                            }}
                          >
                            <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.46C23.21 24 24 23.22 24 22.25V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.57c-1.14 0-2.06-.93-2.06-2.06 0-1.14.92-2.07 2.06-2.07 1.14 0 2.07.93 2.07 2.07 0 1.13-.93 2.06-2.07 2.06zm14.65 12.88h-3.56v-5.87c0-1.4-.03-3.2-1.95-3.2-1.95 0-2.25 1.52-2.25 3.09v5.98H8.67V9h3.42v1.56h.05c.48-.91 1.64-1.86 3.37-1.86 3.6 0 4.27 2.37 4.27 5.45v6.3z" />
                          </svg>
                        </Box>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "0 10%",
                        marginTop: "60px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "25%",
                        backgroundColor: "#5CC0E1",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                        Gangadhar Reddy
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#2d2926" }}>
                        Founder & CEO
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "65px",
                          height: "120px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": { display: "none" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sedan",
                            fontSize: "14px",
                            color: "#333",
                            fontsize: "0.8rem !important",
                            lineheight: "1.4",
                          }}
                        >
                          With over 18 years of experience in Supply Chain
                          Management across notable companies like Ninjacart,
                          Urdoor Step.com, Jubilant Retails, and Godrej Tyson
                          Foods, this passionate innovator has driven product,
                          service, business, marketing, and management
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  width: "230px",
                  height: "350px",
                  border: "1px solid #ccc",
                  marginTop: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  position: "relative",
                  boxShadow:
                    "0 4px 8px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow:
                      "0 8px 16px rgba(0,0,0,0.2), 0 12px 40px rgba(0,0,0,0.19)",
                  },
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Start from the top of the card
                    left: 0,
                    width: "100%",
                    height: "25%",
                    backgroundColor: "#f0f0f0", // Grey background color
                    // Ensure the background is behind the card content
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <Grid container direction="column" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    data-aos="fade-up"
                    data-aos-delay="280"
                    data-aos-duration="3000"
                    sx={{ position: "absolute", top: "-50px" }}
                  >
                    <div
                      className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1718772671917_Avinash%20Sir.png"
                        className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "4px solid white",
                        }}
                      />
                      <a
                        href="https://www.linkedin.com/in/avinash-kumar-7b56878b"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: "absolute",
                          top: "99%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {/* <LinkedInIcon style={{ fontSize: '30px', color: '#0077b5' }} /> */}
                        {/* <FaLinkedinIn style={{ fontSize: '30px', color: '#0077b5', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }} /> */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{
                              width: "24px",
                              height: "24px",
                              fill: "#0077b5",
                            }}
                          >
                            <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.46C23.21 24 24 23.22 24 22.25V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.57c-1.14 0-2.06-.93-2.06-2.06 0-1.14.92-2.07 2.06-2.07 1.14 0 2.07.93 2.07 2.07 0 1.13-.93 2.06-2.07 2.06zm14.65 12.88h-3.56v-5.87c0-1.4-.03-3.2-1.95-3.2-1.95 0-2.25 1.52-2.25 3.09v5.98H8.67V9h3.42v1.56h.05c.48-.91 1.64-1.86 3.37-1.86 3.6 0 4.27 2.37 4.27 5.45v6.3z" />
                          </svg>
                        </Box>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "0 10%",
                        marginTop: "60px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "25%",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                        Avinash Kumar
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#2d2926" }}>
                        COO & Co-founder
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "65px",
                          height: "120px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": { display: "none" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sedan",
                            fontSize: "14px",
                            color: "#333",
                            fontsize: "0.8rem !important",
                            lineheight: "1.4",
                          }}
                        >
                          Co-Founder of Logicarts, where I superhead innovative
                          solutions and drive strategic growth in the tech
                          industry.With a focus on agile methodologies and user
                          centric design, I ensure our products meet the highest
                          standards of excellence.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card
                sx={{
                  width: "230px",
                  height: "350px",
                  border: "1px solid #ccc",
                  marginTop: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  position: "relative",
                  boxShadow:
                    "0 4px 8px rgba(0,0,0,0.2), 0 6px 20px rgba(0,0,0,0.19)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)",
                    boxShadow:
                      "0 8px 16px rgba(0,0,0,0.2), 0 12px 40px rgba(0,0,0,0.19)",
                  },
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Start from the top of the card
                    left: 0,
                    width: "100%",
                    height: "25%", // Adjust height to cover the top part including the circle
                    backgroundColor: "#f0f0f0", // Grey background color
                    // Ensure the background is behind the card content
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                />
                <Grid container direction="column" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    data-aos="fade-up"
                    data-aos-delay="280"
                    data-aos-duration="3000"
                    sx={{ position: "absolute", top: "-50px" }}
                  >
                    <div
                      className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1738654604330_pratyushNew.webp"
                        className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          border: "4px solid white",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                      <a
                        href="https://www.linkedin.com/in/pratyushpundir"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: "absolute",
                          top: "99%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {/* <LinkedInIcon style={{ fontSize: '30px', color: '#0077b5' }} /> */}
                        {/* <FaLinkedinIn style={{ fontSize: '30px', color: '#0077b5', backgroundColor: 'white', borderRadius: '50%', padding: '5px' }} /> */}
                        <Box
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            padding: "0px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style={{
                              width: "24px",
                              height: "24px",
                              fill: "#0077b5",
                            }}
                          >
                            <path d="M22.23 0H1.77C.79 0 0 .78 0 1.75v20.5C0 23.22.79 24 1.77 24h20.46C23.21 24 24 23.22 24 22.25V1.75C24 .78 23.21 0 22.23 0zM7.12 20.45H3.56V9h3.56v11.45zM5.34 7.57c-1.14 0-2.06-.93-2.06-2.06 0-1.14.92-2.07 2.06-2.07 1.14 0 2.07.93 2.07 2.07 0 1.13-.93 2.06-2.07 2.06zm14.65 12.88h-3.56v-5.87c0-1.4-.03-3.2-1.95-3.2-1.95 0-2.25 1.52-2.25 3.09v5.98H8.67V9h3.42v1.56h.05c.48-.91 1.64-1.86 3.37-1.86 3.6 0 4.27 2.37 4.27 5.45v6.3z" />
                          </svg>
                        </Box>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "0 10%",
                        marginTop: "60px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "25%",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px 8px 0 0",
                      }}
                    >
                      <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                        Pratyush Pundir
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#2d2926" }}>
                        Head of Technology
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "65px",
                          height: "120px",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Sedan",
                            fontSize: "14px",
                            color: "#333",
                            fontsize: "0.8rem !important",
                            lineheight: "1.4",
                          }}
                        >
                          With over 15 years of experience, including a decade
                          leading agile teams, I currently manage 35+
                          cross-functional product builders delivering a
                          top-rated Learn and Test prep product in the medical
                          field.Passionate about understanding people
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="mobile-team"
        sx={{
          padding: "5%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "38px",
            lineHeight: "46px",
            color: "#1b8fd2",
            textAlign: "center",
            marginTop: "10%",
          }}
          gutterBottom
          data-aos="fade-up"
          data-aos-delay="120"
          data-aos-duration="3000"
        >
          Meet Our Team
        </Typography>
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          breakpoints={{
            200: {
              // width: 576,
              slidesPerView: 1,
            },
            330: {
              // width: 576,
              slidesPerView: 1,
            },
            600: {
              // width: 576,
              slidesPerView: 2,
            },
            669: {
              // width: 576,
              slidesPerView: 3,
            },
            768: {
              // width: 768,
              slidesPerView: 3,
            },
            800: {
              // width: 768,
              slidesPerView: 3,
            },
          }}
          modules={[Autoplay, Navigation]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <Box>
              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                <img
                  // src={require("./images/Gangadhar_CEO (1).png")}
                  src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474799081_Gangadhar_CEO%2020%281%29.85dc23d69e0002f25743.webp"
                  className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                />
                <a
                  href="https://www.linkedin.com/in/gangadhar-reddy-502806a8"
                  target="_blank"
                >
                  <Typography
                    style={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      padding: "3px",
                      color: "#fff",
                      display: "block",
                      margin: "auto",
                      marginTop: "-6%",
                      zIndex: 10000,
                      position: "relative",
                    }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </a>
              </div>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Gangadhar Reddy
                </Typography>
                <Typography>CEO & Founder</Typography>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box>
              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                <img
                  // src={require("./images/Avinashkumar.png")}
                  src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474791403_Avinashkumar.5f556ad34a9c7a0235c5.webp"
                  className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                />
                <a
                  href="https://www.linkedin.com/in/avinash-kumar-7b56878b"
                  target="_blank"
                >
                  <Typography
                    style={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      padding: "3px",
                      color: "#fff",
                      display: "block",
                      margin: "auto",
                      marginTop: "-6%",
                      zIndex: 10000,
                      position: "relative",
                    }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </a>
              </div>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Avinash Kumar
                </Typography>
                <Typography>COO & Co-founder</Typography>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box>
              <div className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim">
                <img
                  // src={require("./images/Sujit-Jha-1.png")}
                  src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474828045_Sujit-Jha-1.79a207253da7c2e8bbf8.webp"
                  className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                />
                <a
                  href="https://www.linkedin.com/mwlite/in/sujit-jha-3646b665"
                  target="_blank"
                >
                  <Typography
                    style={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      padding: "3px",
                      color: "#fff",
                      display: "block",
                      margin: "auto",
                      marginTop: "-6%",
                      zIndex: 10000,
                      position: "relative",
                    }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </a>
              </div>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Sujit Jha
                </Typography>
                <Typography>Head Of Finance</Typography>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <div
                className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                style={{
                  margin: "auto",
                  display: "block",
                }}
              >
                <img
                  // src={require("./images/VinodArbol.png")}
                  src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1674474839570_VinodArbol.bc92a6ae1feab8cc7bff.webp"
                  className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                />
                <a
                  href="https://www.linkedin.com/mwlite/in/vinod-abrol-622a7713"
                  target="_blank"
                >
                  <Typography
                    style={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      padding: "3px",
                      color: "#fff",
                      display: "block",
                      margin: "auto",
                      marginTop: "-6%",
                      zIndex: 10000,
                      position: "relative",
                    }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </a>
              </div>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Vinod Abrol
                </Typography>
                <Typography>Legal Advisor</Typography>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <div
                className="post_featured sc_team_item_thumb trx_addons_hover trx_addons_hover_style_info_anim"
                style={{
                  margin: "auto",
                  display: "block",
                }}
              >
                <img
                  src="https://hybrid-app-images.s3.ap-south-1.amazonaws.com/1738654604330_pratyushNew.webp"
                  className="attachment-pathfinder-thumb-rectangle size-pathfinder-thumb-rectangle wp-post-image team-image"
                />
                <a
                  href="https://www.linkedin.com/in/pratyushpundir"
                  target="_blank"
                >
                  <Typography
                    style={{
                      backgroundColor: "#1a8fd1",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      padding: "3px",
                      color: "#fff",
                      display: "block",
                      margin: "auto",
                      marginTop: "-6%",
                      zIndex: 10000,
                      position: "relative",
                    }}
                  >
                    <LinkedInIcon />
                  </Typography>
                </a>
              </div>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "5%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Pratyush Pundir
                </Typography>
                <Typography>Head Of Technology</Typography>
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>

      <Box
        className="desktop-about-content"
        sx={{
          padding: "5%",
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "2rem",
                lineHeight: "46px",
                color: "#1b8fd2",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Who We Are?
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              sx={{
                fontWeight: 500,
                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              Logicarts is the fastest growing logistics service provider in
              India catering to more than 2000 pin codes and 180+ cities
              including metros, tier 2 and tier 3. Our aim is to cater to the
              growing demands of “on-time” delivery and efficiently organise the
              operations by use of modern-day technologies. With the use of AI
              and IoT, we are able to facilitate traceability, accountability,
              and transparency in all our processes.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,

                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              From manufacturing hub to doorstep delivery, we specialise in
              end-to-end shipment. We also assist our clients to set up and
              maintaining micro fulfilment centres.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,

                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              Our operating models gives a lot of flexibility to our customers
              to optimise their budget while ensuring best-in-class services.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            marginTop: "5%",
          }}
        >
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "2rem",
                lineHeight: "46px",
                color: "#1b8fd2",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our Vision
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              sx={{
                fontWeight: 500,

                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our vision is to become the most sophisticated, single aggregator
              platform efficiently solving major Logistics problems across
              India.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: "5%",
          }}
        >
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "2rem",
                lineHeight: "46px",
                color: "#1b8fd2",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our Mission
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              sx={{
                fontWeight: 500,

                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              To connect sellers to the consumers on time at an affordable cost.
              To minimise the overheads involved in the entire logistics and
              supply chain operations.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,

                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              To upskill and enable the unorganised sector to leverage
              technology To establish synergies within entire operations and
              enhance the value proposition to our clients and their end
              customers.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="mobile-about-content"
        sx={{
          padding: "5%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "46px",
                color: "#1b8fd2",
                textAlign: "center",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Who We Are?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              Logicarts is the fastest growing logistics service provider in
              India catering to more than 2000 pin codes and 180+ cities
              including metros, tier 2 and tier 3. Our aim is to cater to the
              growing demands of “on-time” delivery and efficiently organise the
              operations by use of modern-day technologies. With the use of AI
              and IoT, we are able to facilitate traceability, accountability,
              and transparency in all our processes.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              From manufacturing hub to doorstep delivery, we specialise in
              end-to-end shipment. We also assist our clients to set up and
              maintaining micro fulfilment centres.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              gutterBottom
            >
              Our operating models gives a lot of flexibility to our customers
              to optimise their budget while ensuring best-in-class services.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            marginTop: "5%",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#1b8fd2",
                textAlign: "center",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our Vision
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our vision is to become the most sophisticated, single aggregator
              platform efficiently solving major Logistics problems across
              India.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: "5%",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "46px",
                color: "#1b8fd2",
                textAlign: "center",
              }}
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              Our Mission
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              To connect sellers to the consumers on time at an affordable cost.
              To minimise the overheads involved in the entire logistics and
              supply chain operations.
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                color: "#A4A4A4",
              }}
              gutterBottom
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              To upskill and enable the unorganised sector to leverage
              technology To establish synergies within entire operations and
              enhance the value proposition to our clients and their end
              customers.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="desktop-about-play"
        sx={{
          width: "100%",
          backgroundImage: `url(${BG})`,
          backgroundRepeat: "no-repeat",
          marginBottom: "5%",
        }}
      >
        <Grid
          className="back-play-about"
          container
          sx={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "10%",
          }}
        >
          <Grid item xs={6}>
            <div
              class="blobs-container"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
              style={{
                cursor: "pointer",
              }}
            >
              <div class="blob white">
                <PlayArrowIcon
                  sx={{
                    fontSize: "4rem",

                    rotate: "85deg",
                    color: "#f35c0491",
                  }}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "55px",
                  lineHeight: "67px",
                  color: "#FFFFFF",
                  width: "61%",
                }}
                data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="3000"
              >
                So that's us. There's no other way to put it.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Card
              sx={{
                padding: "50px",
              }}
              data-aos="fade-left"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "32px",
                  }}
                >
                  OUR HAPPY CLIENTS
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "32px",
                    marginBottom: "30px",
                  }}
                >
                  What Client's Say?
                </Typography>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                  modules={[Autoplay, Navigation]}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#AFAFAF",
                      }}
                    >
                      Working with Logicarts on a daily basis is a pleasure,
                      based on their efficiency when it comes to transporting
                      both our finished and returned goods in a timely and
                      secure manner.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-03.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                          >
                            Amazon
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#AFAFAF",
                      }}
                    >
                      We knew we had to move faster and we needed to work with a
                      logistics partner we knew and trusted. Logicarts
                      understand our business. Its team is reliable and
                      flexible, and they’ll always go the extra mile to make
                      sure we’re happy and we meet our objectives. Once again,
                      they more than met our expectations.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-01.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                          >
                            Flipkart
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#AFAFAF",
                      }}
                    >
                      Our partnership with Logicarts is one of the reasons why
                      the Our deliveries are now our fastest-on time. They’ve
                      not only delivered everything they promised, they’re
                      continuously looking to improve service and efficiency,
                      and their technology gives us the total visibility we need
                      to manage such a large product range and customer base. We
                      have a great working relationship.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-04.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                          >
                            Royaloak
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#AFAFAF",
                      }}
                    >
                      We typically have a need for shipping and logistics
                      companies – so the first three that we have worked with
                      left us feeling that we should just expect subpar customer
                      service in this industry.we so thankful we found Logicarts
                      .Every single person we’ve interacted with from
                      organisation, personable, resourceful, patient, and
                      totally customer focused.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-02.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                          >
                            Loram
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                </Swiper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="mobile-about-play"
        sx={{
          width: "100%",
          backgroundColor: "#0f1122",
        }}
      >
        <Grid
          container
          sx={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "10%",
          }}
        >
          <Grid item xs={12}>
            <div
              class="blobs-container"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              <div
                class="blob white"
                style={{
                  padding: "1.3%",
                }}
              >
                <PlayArrowIcon
                  sx={{
                    fontSize: "4rem",

                    rotate: "85deg",
                    color: "#f35c0491",
                  }}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "2rem",
                  lineHeight: "67px",
                  color: "#FFFFFF",
                }}
                data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="3000"
                gutterBottom
              >
                So that's us. There's no other way to put it.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                padding: "10px",
              }}
              data-aos="fade-left"
              data-aos-delay="120"
              data-aos-duration="3000"
            >
              <CardContent>
                <Typography className="card-head">OUR HAPPY CLIENTS</Typography>
                <Typography className="card-head2">
                  What Client's Say?
                </Typography>
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                  }}
                >
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#AFAFAF",
                        marginTop: "10%",
                      }}
                    >
                      Working with Logicarts on a daily basis is a pleasure,
                      based on their efficiency when it comes to transporting
                      both our finished and returned goods in a timely and
                      secure manner.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-03.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                            className="client-name-about"
                          >
                            Amazon
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        ffontWeight: 500,
                        fontSize: "18px",
                        color: "#AFAFAF",
                        marginTop: "10%",
                      }}
                    >
                      We knew we had to move faster and we needed to work with a
                      logistics partner we knew and trusted. Logicarts
                      understand our business. Its team is reliable and
                      flexible, and they’ll always go the extra mile to make
                      sure we’re happy and we meet our objectives. Once again,
                      they more than met our expectations.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-01.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                            className="client-name-about"
                          >
                            Flipkart
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#AFAFAF",
                        marginTop: "10%",
                      }}
                    >
                      Our partnership with Logicarts is one of the reasons why
                      the Our deliveries are now our fastest-on time. They’ve
                      not only delivered everything they promised, they’re
                      continuously looking to improve service and efficiency,
                      and their technology gives us the total visibility we need
                      to manage such a large product range and customer base. We
                      have a great working relationship.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-04.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                            className="client-name-about"
                          >
                            Royaloak
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#AFAFAF",
                        marginTop: "10%",
                      }}
                    >
                      We typically have a need for shipping and logistics
                      companies – so the first three that we have worked with
                      left us feeling that we should just expect subpar customer
                      service in this industry.we so thankful we found Logicarts
                      .Every single person we’ve interacted with from
                      organisation, personable, resourceful, patient, and
                      totally customer focused.
                    </Typography>
                    <Box
                      sx={{
                        padding: "3%",
                        borderTopWidth: 1,
                        borderTopColor: "grey",
                        marginTop: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box>
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              src={require("./images/clients_FB-02.png")}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              marginTop: "5%",
                              fontWeight: 600,
                            }}
                            className="client-name-about"
                          >
                            Loram
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </SwiperSlide>
                </Swiper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p
                style={{
                  fontWeight: 600,
                }}
              >
                Our Video
              </p>
            </div>
            <div>
              <Button onClick={handleClose}>
                <HighlightOffOutlinedIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* <YouTube videoId="ZM5VTREqhA8" opts={opts} onReady={_onReady} /> */}
          </Box>
        </DialogContent>
      </Dialog>

      <section id="contact-us">
        <FooterAboutus />
      </section>
    </div>
  );
}

export default AboutUs;
